<template>
    <Setup-Step
        @next="f => f()"
        :canNavigate="false"
        hideSkip
        hideYesNo
        :loadingMsg="loadingMsg"
        :settings="settings"
        showNext
        :stage.sync="stage"
        step="courier-order-approval"
        title="Courier Order Approval">
        <template v-slot:body>
            <BT-Courier-Cycle-Header showTrimmings highlightApprove title="Courier Pipeline: Approve Orders" />

            <v-divider class="my-4" />

            <Setup-Block>
                <BT-Entity
                    ignoreID
                    inline
                    navigation="courier-settings"
                    single>
                    <template v-slot="{ item, save }">
                        <v-subheader>Approve Requests To Courier</v-subheader>

                        <p>
                            When a request is made to courier a consignment to its destination, the courier can configure BlitzIt Web to automatically approve the request or await for a user to approve it.  
                            This applies to couriers and suppliers who do their own deliveries.  
                            You can also decide to set it here globally for every client or individually for each client.
                        </p>

                        <v-divider class="my-4" />

                        <v-subheader>Courier Order Approving</v-subheader>

                        <v-row>
                            <v-col cols="8">
                                <BT-Square-Check
                                    @change="save"
                                    falseClass="success--text"
                                    falseIcon="mdi-account-circle-outline"
                                    falseValue="Agreements"
                                    isEditing
                                    :label="item.courierRequestApprovalGuide == 'Settings' ? 'Apply To Every Client' : 'Different For Each Client'"
                                    outlined
                                    trueIcon="mdi-earth"
                                    trueValue="Settings"
                                    v-model="item.courierRequestApprovalGuide" />
                            </v-col>
                            <v-col cols="4" class="align-self-center">
                                <v-slide-x-transition hide-on-leave>
                                    <BT-Square-Check
                                        v-if="item.courierRequestApprovalGuide == 'Settings'"
                                        @change="save"
                                        falseClass="success--text"
                                        falseIcon="mdi-boom-gate"
                                        falseValue="Manual"
                                        isEditing
                                        :label="item.courierRequestApprovalTrigger"
                                        outlined
                                        trueIcon="mdi-boom-gate-up"
                                        trueValue="Auto"
                                        v-model="item.courierRequestApprovalTrigger" />
                                    <p v-else>
                                        (Define this when you add new clients)
                                    </p>
                                </v-slide-x-transition>
                            </v-col>
                        </v-row>

                        <div v-if="settings.data.courieringForSelf">
                            <v-divider class="my-4" />

                            <v-subheader>Self-Couriering</v-subheader>

                            <v-row>
                                <!-- <v-spacer /> -->
                                <v-col cols="4" class="align-self-center">
                                    <v-slide-x-transition hide-on-leave>
                                        <BT-Square-Check
                                            @change="save"
                                            falseClass="success--text"
                                            falseIcon="mdi-boom-gate"
                                            falseValue="Manual"
                                            isEditing
                                            :label="item.courierRequestApprovalTrigger"
                                            outlined
                                            trueIcon="mdi-boom-gate-up"
                                            trueValue="Auto"
                                            v-model="item.courierRequestApprovalTrigger" />
                                    </v-slide-x-transition>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </BT-Entity>

            </Setup-Block>
            
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Courier-Order-Approval-Setup',
    components: {
        BTCourierCycleHeader: () => import('~components/BT-Courier-Cycle-Header.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    }
}
</script>